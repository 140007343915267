import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal, ModalInterface } from '../Modal';
import Loader from 'react-loader-spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './ReportForm.module.scss';
import globalStyles from '../../WifiConnect.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { slackService } from '../../../../api/slack/service';
import { AppConfig } from '../../../../constants/config';
import { Block } from '../Block/Block';
import { timeout } from '../../../../constants/app';
import { getDeviceClient } from '../../../../api/devices/client';
import { DeviceResponse } from '../../../../api/devices/types';

export interface ConvertToCorpFormInterface {
  show(): void;
  hide(): void;
}

interface ConvertToCorpFormProps {
  device: DeviceResponse;
  onSuccess: Function;
  onCancel?: Function;
}

export const ConvertToCorpForm = forwardRef<
  ConvertToCorpFormInterface,
  ConvertToCorpFormProps
>(({ device, onSuccess, onCancel }, ref) => {
  const modalRef = useRef<ModalInterface>(null);
  const { getAccessTokenSilently } = useAuth0();

  const [isError, setIsError] = useState<boolean>(false);
  const [isReporting, setIsReporting] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    show() {
      if (modalRef.current) {
        modalRef.current.show();
        modalRef.current.enableOK();
      }
    },
    hide() {
      if (modalRef.current) {
        modalRef.current.hide();
      }
    },
  }));

  useEffect(() => cleanup(), []);

  const onSubmit = async () => {
    setIsReporting(true);

    if (modalRef.current) {
      modalRef.current.toggleOK();
      modalRef.current.toggleCancel();
    }

    try {
      const token = await getAccessTokenSilently();
      const resp = await getDeviceClient(AppConfig.appEnv, token).updateDevice(
        device.mac,
        {
          mac: device.mac,
          name: device.name,
          type: 'WWIOT',
          wifi_corp_devices: true,
          notes: device.notes,
        },
      );

      cleanup();

      if (onSuccess) {
        onSuccess();
      }
      if (modalRef.current) {
        modalRef.current.hide();
      }
    } catch (e) {
      if (modalRef.current) {
        modalRef.current.enableOK();
      }

      setIsError(true);
      setTimeout(() => setIsError(false), timeout);
    }

    setIsReporting(false);

    if (modalRef.current) {
      modalRef.current.enableCancel();
    }
  };

  const cleanup = () => {
    if (modalRef.current) {
      modalRef.current.disableOK();
    }

    setIsError(false);
  };

  return (
    <Modal
      ref={modalRef}
      title={
        <FormattedMessage
          id="tabs.corporate.convertForm.form.title"
          defaultMessage="Convert device to corporate"
        />
      }
      onOK={onSubmit}
      onCancel={() => {
        if (onCancel) {
          onCancel();
        }

        cleanup();
      }}
      okText={
        isReporting ? (
          <Loader type="Oval" color="#FFF" height={13} width={13} />
        ) : (
          <FormattedMessage
            id="tabs.corporate.convertForm.form.action"
            defaultMessage="Convert"
          />
        )
      }
      isSelfClosable={false}
    >
      {isError ? (
        <Block>
          <div
            className={classNames(
              globalStyles.notification,
              globalStyles.errorMsgInReportModal,
            )}
          >
            <FormattedMessage
              id={`tabs.corporate.convertForm.error`}
              defaultMessage="Internal error. Check your Internet connection and try again"
            />
          </div>
        </Block>
      ) : (
        <></>
      )}

      <FormattedMessage
        id="tabs.corporate.convertForm.form.description"
        defaultMessage="Device will be converted to corporate. Current device owner is {owner}."
        values={{ owner: device.owner }}
      />
    </Modal>
  );
});
