import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  maxDevices,
  personalNetworkDevices,
  notifiableDevices,
  corporateDeviceTypeOptions,
  corporateRegionsOptions,
  amountOfLastDigitsForName,
} from '../../../../constants/app';
import { AppConfig } from '../../../../constants/config';
import { getDeviceClient } from '../../../../api/devices/client';
import { validateMAC } from '../../../../api/devices/helpers';
import { DeviceResponse } from '../../../../api/devices/types';
import styles from '../../WifiConnect.module.scss';
import { Input } from '../Input/Input';
import { Select } from '../Input/Select';
import { getLocationsClient } from '../../../../api/locations/client';
import useAsyncEffect from 'use-async-effect';
import { AdditionalInfo } from '../Input/Additionalnfo';

export interface CorpFormInterface {
  submit: () => void;
  cleanup: () => void;
}

type CorpFormProps = {
  currentMAC?: string;
  className?: any;
  deviceData?: CorpFormState;
  onCreate?: (resp: DeviceResponse) => void;
  onEdit?: (resp: DeviceResponse) => void;
  onSuccess?: () => void;
  onError?: (msg: JSX.Element) => void;
  onFinished?: () => void;
  onSetField?: (name: string, value: any) => void;
  onValidate?: (isValid: boolean) => void;
  onLoad?: () => JSX.Element;
};

export type CorpFormState = {
  mac: string;
  type: string;
  otherType: string;
  location: string;
  region: string;
  notes: string;
};

type CorpErrors = {
  [key: string]: boolean;
};

export const CorpForm = forwardRef<CorpFormInterface, CorpFormProps>(
  (
    {
      currentMAC,
      className,
      deviceData,
      onCreate,
      onEdit,
      onFinished,
      onSuccess,
      onError,
      onSetField,
      onValidate,
      onLoad,
    },
    ref,
  ) => {
    const [isInAction, setIsInAction] = useState<boolean>(false);
    const [, setIsFinished] = useState<boolean>(false);
    const [isOtherType, setIsOtherType] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deviceName, setDeviceName] = useState<string>('');

    const { getAccessTokenSilently } = useAuth0();
    const intl = useIntl();

    const initialFormState: CorpFormState = {
      mac: '',
      type: '',
      otherType: '',
      location: '',
      region: '',
      notes: '',
    };

    const initialErrorsState: CorpErrors = {
      name: false,
      mac: false,
      otherType: false,
      location: false,
    };

    const [mac, setMAC] = useState<string>('');
    const [form, setForm] = useState<CorpFormState>(initialFormState);
    const [errors, setErrors] = useState<CorpErrors>(initialErrorsState);

    useEffect(() => {
      let region = form.region ? form.region : 'REGION';

      let macWithoutDashesAndColons = form.mac.replace(/[. :-]/g, '');
      let lastSixDigitsOfMac = validateMAC(form.mac)
        ? macWithoutDashesAndColons.slice(-amountOfLastDigitsForName)
        : 'LAST-6-DIGITS-OF-MAC';
      let deviceType = form.type ? form.type : 'TYPE';

      let name = region + '-' + deviceType + '-' + lastSixDigitsOfMac;
      setDeviceName(name);
    }, [form.region, form.mac, form.type]);

    const actions = {
      onCreate: async () => {
        setIsInAction(true);

        if (formIsValid()) {
          const token = await getAccessTokenSilently();
          const resp = await getDeviceClient(
            AppConfig.appEnv,
            token,
          ).createDevice({
            mac: form.mac,
            name: deviceName,
            type: 'WWIOT',
            location_uuid: form.location,
            wifi_corp_devices: true,
            notes: form.notes,
          });

          if ('status' in resp) {
            if (onError) {
              onError(
                <div className={classNames(className, styles.textFieldWrapper)}>
                  <div
                    className={classNames(styles.notification, styles.errorMsg)}
                  >
                    <FormattedMessage
                      id={`tabs.corporate.messages.errors.${resp.status}`}
                      values={{ maxDevices: maxDevices }}
                      defaultMessage="Internal error. Check your Internet connection and try again"
                    />
                  </div>
                </div>,
              );
            }
          } else {
            if (onCreate) {
              onCreate(resp);
            }

            setIsFinished(true);
            cleanup();

            if (onSuccess) {
              onSuccess();
            }
          }

          if (onFinished) {
            onFinished();
          }
        }

        setIsInAction(false);
      },
      onEdit: async () => {
        setIsInAction(true);

        if (formIsValid()) {
          if (!currentMAC) {
            return;
          }

          const token = await getAccessTokenSilently();
          const resp = await getDeviceClient(
            AppConfig.appEnv,
            token,
          ).updateDevice(mac, {
            mac: form.mac,
            name: deviceName,
            type: 'WWIOT',
            location_uuid: form.location,
            wifi_corp_devices: true,
            notes: form.notes,
          });

          if ('status' in resp) {
            if (onError) {
              onError(
                <div className={classNames(className, styles.textFieldWrapper)}>
                  <div
                    className={classNames(styles.notification, styles.errorMsg)}
                  >
                    <FormattedMessage
                      id={`tabs.corporate.messages.errors.${resp.status}`}
                      values={{ maxDevices: maxDevices }}
                      defaultMessage="Internal error. Check your Internet connection and try again"
                    />
                  </div>
                </div>,
              );
            }
          } else {
            if (onEdit) {
              onEdit(resp);
            }

            setIsFinished(true);
            cleanup();

            if (onSuccess) {
              onSuccess();
            }
          }

          if (onFinished) {
            onFinished();
          }
        }

        setIsInAction(false);
      },
      loadAdditionalLocations: async (locationUUID: string) => {
        setIsInAction(true);

        const token = await getAccessTokenSilently();
        const resp = await getLocationsClient(
          AppConfig.appEnv,
        ).getLocationByUUID(token, locationUUID, navigator.language);

        if ('status' in resp) {
          if (onError) {
            onError(
              <div className={classNames(className, styles.textFieldWrapper)}>
                <div
                  className={classNames(styles.notification, styles.errorMsg)}
                >
                  <FormattedMessage
                    id={`tabs.corporate.messages.errors.${resp.status}`}
                    defaultMessage="Failed to retrieve location. Check your Internet connection and try again"
                  />
                </div>
              </div>,
            );
          }
        }

        setIsInAction(false);
      },
    };

    useImperativeHandle(ref, () => ({
      submit() {
        onCreate ? actions.onCreate() : actions.onEdit();
      },
      cleanup() {
        cleanup();
      },
    }));

    useAsyncEffect(async () => {
      setIsLoading(true);

      if (currentMAC) {
        setMAC(currentMAC);
      }

      if (deviceData) {
        if (!notifiableDevices.includes(deviceData.type)) {
          deviceData.location = '';
        }

        setForm(deviceData);
        setIsOtherType(
          deviceData.otherType === undefined || deviceData.otherType.length > 0,
        );
      }

      setIsLoading(false);
      return () => {
        setMAC('');
        setForm(initialFormState);
      };
    }, [currentMAC, deviceData]);

    useEffect(() => {
      return onValidate ? onValidate(formIsValid()) : undefined;
    });

    const cleanup = () => {
      setForm(initialFormState);
      setErrors(initialErrorsState);

      if (onSetField) {
        onSetField('type', '');
        onSetField('mac', '');
        onSetField('name', '');
        onSetField('location', '');
        onSetField('otherType', '');
        onSetField('region', '');
        onSetField('notes', '');
      }
    };

    const updateField = useCallback(
      (name: string, val: string) => {
        if (name === 'type') {
          setForm((prev) => ({ ...prev }));
        }

        validateField(name, val);
        setForm((prev) => ({ ...prev, [name]: val }));

        if (onSetField) {
          onSetField(name, val);
        }
      },
      [onSetField],
    );

    const validateField = (name: string, val: string) => {
      if (!val) {
        setErrors((prev) => ({ ...prev, [name]: true }));
      } else {
        if (name === 'mac') {
          setErrors((prev) => ({ ...prev, [name]: !validateMAC(val) }));
          return;
        }

        setErrors((prev) => ({ ...prev, [name]: false }));
      }
    };

    const formIsValid = (): boolean => {
      if (isInAction) {
        return false;
      }

      if (
        errors.mac ||
        errors.name ||
        errors.location ||
        (errors.otherType && isOtherType)
      ) {
        return false;
      }

      return !(
        form.mac === '' ||
        form.region === '' ||
        form.type === '' ||
        (form.location === '' && notifiableDevices.includes(form.type)) ||
        (form.location === undefined &&
          notifiableDevices.includes(form.type)) ||
        personalNetworkDevices.includes(form.type)
      );
    };

    return (
      <>
        {isLoading && onLoad ? (
          onLoad()
        ) : (
          <>
            <>
              <div className={classNames(className, styles.textFieldWrapper)}>
                <Input
                  id={'corp-mac-input'}
                  placeholder={intl.formatMessage({
                    id: 'tabs.corporate.form.fields.mac.placeholder',
                    defaultMessage: "Please enter device's MAC address",
                  })}
                  labelText={
                    <FormattedMessage
                      id="tabs.corporate.form.fields.mac.label"
                      defaultMessage="MAC address"
                    />
                  }
                  text={form.mac}
                  isError={errors.mac}
                  onChange={(e) => updateField('mac', e.target.value)}
                />
              </div>

              <div className={classNames(className, styles.textFieldWrapper)}>
                <Select
                  label={
                    <label className="ray-select__label">
                      <FormattedMessage
                        id="tabs.corporate.form.fields.regions.label"
                        defaultMessage="Regions"
                      />
                    </label>
                  }
                  items={
                    onCreate ? corporateRegionsOptions : corporateRegionsOptions
                  }
                  mapper={(region: string) => (
                    <option key={region} value={region}>
                      {intl.formatMessage({
                        id: `tabs.corporate.regions.${region}`,
                        defaultMessage: region,
                      })}
                    </option>
                  )}
                  currentItem={form.region}
                  onSet={(region: string) => {
                    updateField('region', region);
                  }}
                />
              </div>

              <div className={classNames(className, styles.textFieldWrapper)}>
                <Select
                  label={
                    <label className="ray-select__label">
                      <FormattedMessage
                        id="tabs.corporate.form.fields.type.label"
                        defaultMessage="Device type"
                      />
                    </label>
                  }
                  currentItem={form.type}
                  items={
                    onCreate
                      ? corporateDeviceTypeOptions
                      : corporateDeviceTypeOptions
                  }
                  mapper={(deviceType: string) => (
                    <option key={deviceType} value={deviceType}>
                      {intl.formatMessage({
                        id: `tabs.corporate.types.${deviceType}`,
                        defaultMessage: deviceType,
                      })}
                    </option>
                  )}
                  onSet={(deviceType: string) => {
                    updateField('type', deviceType);
                  }}
                />
              </div>

              <div className={classNames(className, styles.textFieldWrapper)}>
                <Input
                  id={'corp-name-input'}
                  placeholder={intl.formatMessage({
                    id: 'tabs.corporate.form.fields.name.placeholder',
                    defaultMessage: "Please enter device's name",
                  })}
                  labelText={
                    <FormattedMessage
                      id="tabs.corporate.form.fields.name.label"
                      defaultMessage="Device name"
                    />
                  }
                  isReadonly={true}
                  maxLength={30}
                  isError={errors.name}
                  text={deviceName}
                  onChange={(e) => updateField('name', deviceName)}
                  isDisabled={true}
                />
              </div>

              <div className={classNames(className, styles.textFieldWrapper)}>
                <AdditionalInfo
                  currentItem={form.notes}
                  onChange={(e) => updateField('notes', e.target.value)}
                />
              </div>
            </>
          </>
        )}
      </>
    );
  },
);
