import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from '../../WifiConnect.module.scss';
import { FormattedMessage } from 'react-intl';

type SearchByMacInputProps = {
  id: string;
  placeholder: string;
  labelText: JSX.Element;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  searchHandle: React.MouseEventHandler<HTMLButtonElement>;
  clearHandle: React.MouseEventHandler<HTMLButtonElement>;
  isSearchDisabled: boolean;
};

export const SearchByMacInput: React.FC<SearchByMacInputProps> = ({
  id,
  placeholder,
  labelText,
  maxLength,
  onChange,
  value,
  searchHandle,
  clearHandle,
  isSearchDisabled,
}) => {
  return (
    <div className={`ray-text-field`}>
      <input
        className={'ray-text-field__input'}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        value={value}
      />

      <label className="ray-text-field__label" htmlFor={id}>
        {labelText}
      </label>

      <button
        className={'ray-button ray-button--compact ray-button--secondary'}
        onClick={clearHandle}
      >
        <FormattedMessage
          id="tabs.corporate.input.guest_search.buttons.clear"
          defaultMessage="Clear"
        />
      </button>
      <button
        className={'ray-button ray-button--compact ray-button--primary'}
        onClick={searchHandle}
        disabled={isSearchDisabled}
      >
        <FormattedMessage
          id="tabs.corporate.input.guest_search.buttons.search"
          defaultMessage="Search"
        />
      </button>
    </div>
  );
};
