import styles from '../../WifiConnect.module.scss';
import { FormattedMessage } from 'react-intl';
import { SearchInput } from '../Input/Search';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { DeviceResponse } from '../../../../api/devices/types';
import { getDeviceClient } from '../../../../api/devices/client';
import { AppConfig } from '../../../../constants/config';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import Loader from 'react-loader-spinner';
import { validateMAC } from '../../../../api/devices/helpers';
import {
  ConvertToCorpForm,
  ConvertToCorpFormInterface,
} from '../Forms/ConvertToCorporateDeviceForm';
import { timeout } from '../../../../constants/app';
import { Block } from '../Block/Block';
import { SearchByMacInput } from '../Input/SearchByMac';
import { Device } from '../Table/DevicesTable';

export type CorpSearchGuestDeviceProps = {
  className?: any;
  loadDevices: Function;
};

export const CorpSearchGuestDevice: React.FC<CorpSearchGuestDeviceProps> = ({
  className,
  loadDevices,
}) => {
  const [guestDevice, setGuestDevice] = useState<DeviceResponse>({
    mac: '',
    name: '',
    type: '',
    owner: '',
    location_uuid: '',
    is_notificated: false,
    is_warning: false,
    notes: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [mac, setMac] = useState<string>('');
  const { getAccessTokenSilently } = useAuth0();
  const intl = useIntl();
  const [status, setStatus] = useState<string>('');
  const [isReportSent, setIsReportSent] = useState<boolean>(false);
  const reportModalRef = useRef<ConvertToCorpFormInterface>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const initialGuestDevice = {
    mac: '',
    name: '',
    type: '',
    owner: '',
    location_uuid: '',
    is_notificated: false,
    is_warning: false,
    notes: '',
  };

  const getGuestDevice = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    const resp = await getDeviceClient(AppConfig.appEnv, token).getDeviceByMac(
      mac,
    );

    if ('status' in resp) {
      setStatus(resp.status);
      setGuestDevice({
        mac: '',
        name: '',
        type: '',
        owner: '',
        location_uuid: '',
        is_notificated: false,
        is_warning: false,
        notes: '',
      });
    } else {
      setGuestDevice(resp);
      setStatus('');
    }

    setLoading(false);
  };

  const handleSearchInput = (value: string) => {
    setInputValue(value);
    setMac(value);
  };

  const handleClearInput = () => {
    setGuestDevice(initialGuestDevice);
    setStatus('');
    setMac('');
    setInputValue('');
  };

  return (
    <>
      <div className={className}>
        <div className={styles.mediumTitle} style={{ marginBottom: 8 }}>
          <h3 style={{ marginBottom: '6px' }}>
            <FormattedMessage
              id="tabs.corporate.input.guest_search.header"
              defaultMessage="Search device by MAC"
            />
          </h3>
        </div>
      </div>

      <ConvertToCorpForm
        ref={reportModalRef}
        device={guestDevice}
        onSuccess={() => {
          setIsReportSent(true);
          setTimeout(() => setIsReportSent(false), timeout);
          setGuestDevice({
            mac: '',
            name: '',
            type: '',
            owner: '',
            location_uuid: '',
            is_notificated: false,
            is_warning: false,
            notes: '',
          });
          loadDevices();
        }}
      />

      <div className={'ray-grid'}>
        <div className={'ray-grid__cell--span-full'}>
          <SearchByMacInput
            id={'search_guest_device'}
            placeholder={intl.formatMessage({
              id: 'tabs.corporate.input.guest_search.placeholder',
              defaultMessage: "Please enter the device's full MAC address",
            })}
            value={inputValue}
            labelText={
              <FormattedMessage
                id="tabs.corporate.input.guest_search.title"
                defaultMessage="Search device by MAC"
              />
            }
            onChange={(e) => {
              handleSearchInput(e.target.value);
            }}
            searchHandle={getGuestDevice}
            clearHandle={handleClearInput}
            isSearchDisabled={!validateMAC(mac)}
          ></SearchByMacInput>
        </div>
      </div>

      <div>
        {loading ? (
          <div className={styles.searchByMacLoader}>
            <Loader type="Oval" color="#00F" height={40} width={40} />
          </div>
        ) : status === 'device_not_found' ? (
          <div
            className={classNames(
              'ray-grid__cell--span-full',
              styles.textFieldWrapper,
            )}
          >
            <div className={styles.notification}>
              <FormattedMessage
                id="tabs.corporate.messages.errors.device_not_found"
                defaultMessage="Device not found"
              />
            </div>
          </div>
        ) : guestDevice.mac !== '' ? (
          <div className="ray-grid__cell--span-full">
            <table className="ray-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="tabs.corporate.table.header.columns.0"
                      defaultMessage="Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="tabs.corporate.table.header.columns.1"
                      defaultMessage="MAC address"
                    />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.tableCol}>{guestDevice.name}</td>
                  <td className={styles.tableCol}>{guestDevice.mac}</td>
                  <td
                    className={styles.tableCol}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {guestDevice.type !== 'WWIOT' ? (
                      <button
                        className={classNames(
                          'ray-button ray-button--secondary ray-button--compact',
                        )}
                        onClick={() => {
                          if (reportModalRef.current) {
                            reportModalRef.current.show();
                          }
                        }}
                      >
                        <FormattedMessage
                          id="tabs.corporate.table.actions.convert"
                          defaultMessage="Convert"
                        />
                      </button>
                    ) : (
                      <FormattedMessage
                        id="tabs.corporate.table.actions.converted"
                        defaultMessage="Converted"
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Block>
        {isReportSent ? (
          <div
            className={classNames(
              styles.successfulNotification,
              styles.notification,
            )}
          >
            <FormattedMessage
              id="tabs.corporate.messages.afterConvert"
              defaultMessage="Device {name} was converted to corporate!"
              values={{ name: guestDevice.name }}
            />
          </div>
        ) : (
          <></>
        )}
      </Block>
    </>
  );
};
