import {
  DeviceRequest,
  DeviceResponse,
  ListDevices,
  ErrorResponse,
} from './types';

const appUrls: { [key: string]: string } = {
  development: 'http://localhost:8080/api/v1/devices',
  staging:
    'https://wpa-enterprise-enrollment.phoenix.dev.wwrk.co/api/v1/devices',
  production:
    'https://wpa-enterprise-enrollment.hydra.prod.wwrk.co/api/v1/devices',
};

export const getDeviceClient = (appEnv: string, token: string) => ({
  getListOfDevices: async (
    limit: number,
    offset: number,
  ): Promise<ListDevices | ErrorResponse> => {
    const reqURL = `${appUrls[appEnv]}?limit=${limit}&offset=${offset}`;
    const response = await fetch(reqURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  },

  createDevice: async (
    data: DeviceRequest,
  ): Promise<DeviceResponse | ErrorResponse> => {
    const response = await fetch(appUrls[appEnv], {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    return response.json();
  },

  updateDevice: async (
    mac: string,
    data: DeviceRequest,
  ): Promise<DeviceResponse | ErrorResponse> => {
    let url = `${appUrls[appEnv]}/${mac}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    return response.json();
  },

  deleteDevice: async (
    mac: string,
    wifiCorpDevices: boolean,
  ): Promise<ErrorResponse | null> => {
    let url = `${appUrls[appEnv]}/${mac}?wifi_corp_devices=${wifiCorpDevices}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 204) {
      return null;
    }
    return response.json();
  },

  getListOfDevicesByType: async (
    limit: number,
    offset: number,
    deviceType: string,
  ): Promise<ListDevices | ErrorResponse> => {
    const reqURL = `${appUrls[appEnv]}?limit=${limit}&offset=${offset}&device_type=${deviceType}`;
    const response = await fetch(reqURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  },

  getDeviceByMac: async (
    mac: string,
  ): Promise<DeviceResponse | ErrorResponse> => {
    const reqURL = `${appUrls[appEnv]}/${mac}`;
    const response = await fetch(reqURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  },
});
